(function () {
  'use strict';

  angular
    .module('neo.home.phases')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.phases', {
        url: '/phases',
        templateUrl: 'home/phases/phases.tpl.html',
        controller: 'PhasesCtrl',
        controllerAs: 'vm',
        resolve: {
          phases: function (Phases) {
            return Phases.query().$promise;
          }
        }
      });
  }
}());
